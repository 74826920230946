import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const About = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">About</Typography>
      <br />
      <Typography variant="body1">
        This website implements the logistic regression model described in the paper:<br /><br />
        <b>&quot;Integrating electronic health data records to develop and validate a predictive model of hospital-acquired acute
        kidney injury in non-critically ill patients&quot;</b>
      </Typography>
      <Typography variant="body1">
        <br />
        by
      </Typography>
      <Typography variant="body2" style={{ fontSize: 12 }}>
        Del Carpio, Jacqueline; Arnau de Vilanova University Hospital, Nephrology; Autonomous University of Barcelona Faculty of Medicine,<br />
      Segarra, Alfons; Arnau de Vilanova University Hospital, Nephrology; Vall d&apos;Hebron University Hospital, Nephrology<br />
      Marco, Maria Paz; Arnau de Vilanova University Hospital, Nephrology<br />
      Jatem, Elias; Arnau de Vilanova University Hospital, Nephrology<br />
      Gonzalez, Jorge; Arnau de Vilanova University Hospital, Nephrology<br />
      Chang, Pamela; Arnau de Vilanova University Hospital, Nephrology<br />
      Ramos Terrades, Natàlia; Vall d&apos;Hebron University Hospital, Nephrology<br />
      de la Torre, Judith; Vall d&apos;Hebron Hospital Universitari, Nephrology; Althaia Foundation of Manresa, Nephrology<br />
      Prat, Joana; Vall d&apos;Hebron University Hospital, Informatics; Consorci Parc de Salut MAR de Barcelona, Informatics<br />
      Torres, Maria Jesús; Vall d&apos;Hebron University Hospital, Informatics; Catalan Institute of Health, Information<br />
      Montoro-Ronsano, José-Bruno; Vall d&apos;Hebron University Hospital, Pharmacy<br />
      Ibarz, Merce; Arnau de Vilanova University Hospital, Laboratory<br />
      Picó, Silvia; Arnau de Vilanova University Hospital, Laboratory<br />
      Falcon, Gloria; Catalan Institute of Health, Technical secretary and Territorial Management<br />
      Canales, Marina; Catalan Institute of Health, Technical secretary and Territorial Management<br />
      Huertas, Elisard; Catalan Institute of Health, Informatics<br />
      Romero, Iñaki; Catalan Institute of Health, Territorial Management Information Systems<br />
      Nieto, Nacho; Vall d&apos;Hebron University Hospital, Informatics; Catalan Institute of Health, Information, Southern Metropolitan Territorial Management<br />
      </Typography>
      <Typography variant="body1">
      <br />
        The model and the web have been implemented by <a href="https://amalfianalytics.com" target="_blank" rel="noreferrer">Amalfi Analytics</a>.
        Amalfi Analytics creates data-analytic platforms powered by Machine Learning to make healthcare organizations more efficient.
      </Typography>
      <Grid container>

        <Grid item xs={6}>
          <a href="http://www.icslleida.cat/hospital/" target="_blank" rel="noreferrer">
            <img src="/logoics.gif" style={{ width: '100%', paddingTop: 70 }} />
          </a>
        </Grid>
        <Grid item xs={6}>
          <a href="https://amalfianalytics.com" target="_blank" rel="noreferrer">
            <img src="/logo-amalfi.png" style={{ width: '100%', padding: 20 }} />
          </a>
        </Grid>
        {/* <Grid item xs={6}>
          <a href="https://www.amgen.com/" target="_blank" rel="noreferrer">
            <img src="/logo-amgen.png" style={{ width: '100%', padding: 20, paddingTop: 50}} />
          </a>
        </Grid> */}

      </Grid>


    </>
  )
}

export default About
