import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { VARIABLES_COEFFICIENTS, INTERCEPT_VARIABLE, NUMERIC_VARIABLES, CATEGORICAL_VARIABLES } from '../constants/constants'
import { doPrediction } from '../lib/predict'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1)
  }
}))

const Predict = (): JSX.Element => {
  const classes = useStyles()
  const initState: { [variable: string]: string | number } = {}
  //initboolVars
  Object.keys(VARIABLES_COEFFICIENTS)
    .filter(variable => !NUMERIC_VARIABLES.includes(variable) && !CATEGORICAL_VARIABLES.find(cat => cat.key === variable))
    .forEach(variable => { initState[variable] = 0 })

  // A bit hardcoded I know... :)
  initState['CKD_stage'] = 1
  initState['Age'] = 70

  const [formState, setFormState] = useState<{ [variable: string]: string | number }>({ ...initState, [INTERCEPT_VARIABLE]: VARIABLES_COEFFICIENTS[INTERCEPT_VARIABLE] })
  const [formError, setFormError] = useState<{ [variable: string]: string | undefined }>({})
  const [predictionResult, setPredictionResult] = useState<number | null>(null)
  const submitResult = () => {
    const missingVars = Object.keys(VARIABLES_COEFFICIENTS).filter(variable => formState[variable] === undefined)
    missingVars.forEach(variable => {
      setFormError({ ...formError, [variable]: 'The value must not be empty.' })
    })
    if (missingVars.length === 0) {
      const numericValues: { [variable: string]: number } = {}
      Object.keys(formState).map(key => {
        numericValues[key] = Number(formState[key])
      })
      setPredictionResult(doPrediction(VARIABLES_COEFFICIENTS, numericValues))
    }
  }
  useEffect(submitResult)

  return (
    <div>
      <Typography variant="h5">Predict</Typography>

      <Typography variant="body1">
        Enter the value for the predictive variables and the predicted
        probability will update automatically.
        The default intercept value is as in the original model.
        Change to make the model more or less sensitive.
      </Typography>
      <form onKeyPress={(e) => e.key == 'Enter' && submitResult()}>
        {
          Object.keys(VARIABLES_COEFFICIENTS).map(variable => (
            <FormControl className={classes.formControl} key={variable}>
              {
                NUMERIC_VARIABLES.includes(variable) && (
                  <TextField
                    required
                    label={variable}
                    // InputProps={{readOnly: variable === INTERCEPT_VARIABLE}}
                    // variant={variable === INTERCEPT_VARIABLE ? 'filled' : 'standard'}
                    onChange={e => {
                      const n = e.target.value
                      if (isNaN(n as unknown as number)) {
                        setFormError({ ...formError, [variable]: 'The value must be a number.' })
                      } else {
                        setFormState({ ...formState, [variable]: e.target.value })
                        setFormError({ ...formError, [variable]: undefined })
                      }
                      submitResult()
                    }}
                    error={!!formError[variable]}
                    helperText={formError[variable] || ''}
                    value={formState[variable] || ''}
                  />
                )
              }
              {
                !NUMERIC_VARIABLES.includes(variable) && !CATEGORICAL_VARIABLES.find(cat => cat.key === variable) && (
                  < FormControlLabel
                    control={
                      <Checkbox
                        checked={!!formState[variable]}
                        onChange={(_e, checked) => {
                          setFormState({ ...formState, [variable]: checked ? 1 : 0 })
                          submitResult()
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={variable}
                  />
                )
              }
              {
                CATEGORICAL_VARIABLES.find(cat => cat.key === variable) && (
                  <TextField
                    select
                    value={formState[variable] || ''}
                    label={variable}
                    error={!!formError[variable]}
                    helperText={formError[variable] || ''}
                    style={{ minWidth: 150 }}
                    onChange={(e) => {
                      setFormState({ ...formState, [variable]: Number(e.target.value) })
                      setFormError({ ...formError, [variable]: undefined })
                      submitResult()
                    }}
                  >
                    {
                      CATEGORICAL_VARIABLES.find(cat => cat.key === variable)?.categories.map(category => (
                        <MenuItem key={category} value={category}>
                          {category}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                )
              }
            </FormControl>
          ))
        }
        <br />
        <br />
        {
          predictionResult !== null && (
            <Typography variant="body1">
              The predicted probability of developing Acute Kidney Injury is <b>{(predictionResult * 100).toFixed(3)}%</b>.
            </Typography>
          )
        }
      </form>
    </div>
  )
}

export default Predict
