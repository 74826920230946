import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logoIcsContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    borderRadius: '5%'
  }
}))

const AppHeader = (): JSX.Element => {
  const classes = useStyles()
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h4" style={{ fontWeight: 'bolder'}}>
          PRED-AKI: Prediction of Acute Kidney Injury
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.logoIcsContainer}>
          <a href="http://www.icslleida.cat/hospital/" target="_blank" rel="noreferrer">
          <img src="/logoics.gif" height="35" />
          </a>
        </div>
        <div className={classes.logoIcsContainer}>
          <a href="https://amalfianalytics.com" target="_blank" rel="noreferrer">
            <img src="/logo-amalfi.png" height="35" />
          </a>
        </div>
        {/* <div className={classes.logoIcsContainer}>
          <a href="https://www.amgen.com/" target="_blank" rel="noreferrer">
            <img src="/logo-amgen.png" height="35" />
          </a>
        </div> */}
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader
