import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import { Switch, Route, Redirect } from 'react-router-dom'
import CookieConsent from "react-cookie-consent"
import Predict from './pages/Predict'
// import PredictBulk from './pages/PredictBulk'
import Register from './pages/Register'
import About from './pages/About'
import AppHeader from './components/AppHeader'
import AppDrawer from './components/AppDrawer'
import { initGA } from './lib/ga'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '90vh'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100%',
    width: '100%'
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    height: '100%',
    flexGrow: 1,
    maxWidth: 800
  }
}))

function App (): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppHeader />
      <AppDrawer />
      <main className={classes.content}>
        <Toolbar />
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <Switch>
              <Route path="/predict" render={() => <Predict />} />
              {/* <Route path="/predict-bulk" render={() => <PredictBulk />} /> */}
              <Route path="/register" render={() => <Register />} />
              <Route path="/about" render={() => <About />} />
              <Route path="/" render={() => <Redirect to='/predict' />} />
            </Switch>
          </Paper>
          <CookieConsent enableDeclineButton style={{zIndex:9999}} onAccept={initGA}>
            This website uses cookies.
          </CookieConsent>
        </div>

      </main>

    </div>
  )
}

export default App
