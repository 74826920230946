import React from 'react'
import Typography from '@material-ui/core/Typography'

const Register = (): JSX.Element => {
  return (
    <>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSc8la-2lzC-3vEsQHGPGh4v4Fd5WwTIhsEJsLeQWbJZZsRokg/viewform?embedded=true"
        width="100%"
        height="95%"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
      >
        Loading…
        </iframe>
    </>
  )
}

export default Register
