const raw = `0;Age;0,0348905768753499
1;Gender=female;0,17399522283454175
2;Hypertension;0,226999455384805
3;Diabetes;0,6494853881272763
4;Malignancy;0,3139507787039561
5;PVD;0,3588927780011647
6;CEREBROVASCULAR;0,20833192178729648
7;Malnutrition;0,4247253948640906
8;Anaemia;0,8183554760690568
9;CCHF;0,5216246842963741
10;IHD;0,3056568433174955
11;COPD;0,3376275113718003
12;ARF;1,2766819235296676
13;Cirrhosis;0,9975597027360628
14;SIRS;1,2298291418956102
15;Shock;1,900743230763538
16;Digestive;0,03271185136260979
17;AIDS;-0,8315747313958705
18;Urologic;0,6789110864512115
19;Connective_tissue_disease;0,31766105247372295
20;Major_surgery;0,9791244381241458
21;CKD_stage;0,6745443373534667
22;AHF;0,6821801580996405
23;Contrast;0,5137397939674798
24;Nephrotoxic;0,4972196969192949
25;Urgent_admission;0,6652025843705837
26;Intercept;-9,315` //truncated: -9,31513426016627

export const NUMERIC_VARIABLES = ['Age', 'Intercept']
export const CATEGORICAL_VARIABLES = [{ key: 'CKD_stage', categories: [1, 2, 3, 4] }]

const coeffs: { [variable: string]: number } = {}

raw.split('\n')
    .map(row => row.split(';').slice(1))
    .forEach(([variable, value]) => { coeffs[variable] = Number(value.replace(/,/g, '.')) })

export const VARIABLES_COEFFICIENTS = coeffs
export const INTERCEPT_VARIABLE = 'Intercept'
export const PREDICTION_COLUMN = 'PREDICTION'
