import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import {
  useLocation,
  useHistory
} from "react-router-dom"


const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  }
}))

const AppDrawer = (): JSX.Element => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <nav>
          <List>
            <ListItem button onClick={() => history.push('/predict')}>
              <ListItemText
                classes={{ primary: location.pathname === '/predict' ? classes.selected : undefined }}
              >Predict</ListItemText>
            </ListItem>
            <ListItem button onClick={() => history.push('/about')}>
              <ListItemText
                classes={{ primary: location.pathname === '/about' ? classes.selected : undefined }}
              >About</ListItemText>
            </ListItem>
            <ListItem button onClick={() => history.push('/register')}>
              <ListItemText
                classes={{ primary: location.pathname === '/register' ? classes.selected : undefined }}
              >Register</ListItemText>
            </ListItem>
          </List>
        </nav>
      </div>
    </Drawer>
  )
}

export default AppDrawer
